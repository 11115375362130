export var tamil = ["arse",
"assfukka",
"asshole",
"b!tch",
"b00bs",
"ballsack",
"bastard",
"beastial",
"bestial",
"bitch",
"blow job",
"blowjob",
"boner",
"butthole",
"buttplug",
"cocksucker",
"carpet muncher",
"cock",
"cockface",
"cockhead",
"cockmunch",
"cocks",
"cocksuck",
"cocksuka",
"cocksukka",
"cok",
"cokmuncher",
"coksucka",
"cummer",
"cumming",
"cumshot",
"cunilingus",
"cunillingus",
"cunnilingus",
"cuntlick",
"cunts",
"cyalis",
"cyberfuc",
"dick",
"dickhead",
"ejakulate",
"fagging",
"faggitt",
"faggot",
"fagot",
"fagots",
"fatass",
"fellatio",
"fuck",
"fudgepacker",
"fukkin",
"gangbang",
"gaylord",
"gaysex",
"goatse",
"hardcoresex",
"horny",
"hotsex",
"jack-off",
"jackoff",
"jerk-off",
"jizz",
"masochist",
"master-bate",
"mayiru",
"mofo",
"muthafecker",
"nazi",
"nigga",
"nigger",
"numbnuts",
"nutsack",
"orgasm",
"orgasms",
"phonesex",
"pimps",
"poop",
"porn",
"prick",
"pube",
"pudungi",
"pussy",
"rectum",
"retard",
"sadist",
"screwing",
"scrotum",
"semen",
"sex",
"shemale",
"shit",
"slut",
"son-of-a-bitch",
"thevidiya",
"tits",
"titties",
"tittywank",
"titwank",
"twat",
"twathead",
"twatty",
"viagra",
"wank",
"whore",
"willies",
"xrated",
"xxx"]

import React, { useRef, useState } from 'react'
import { TransparentButton } from '../components/Button';
import { compress, compressAccurately, urltoBlob, filetoDataURL } from 'image-conversion';

import { Tabs, Tab } from 'react-bootstrap';
import Resizer from "react-image-file-resizer";
import watermark from "watermarkjs";
import Pyro from '../components/Pyro';
// import { Web3Storage } from 'web3.storage'


function Home() {
    return (
        <div>
            {/* Home */}
        </div>
    )
}

function CMS() {
    return (
        <div>
            <a href="https://homekonnectcms.prismic.io" target="_blank">Click here to log in to the content management system</a>
        </div>
    )
}


function ImageLink() {
    const [ourlist, setList] = useState([])
    const token_ref = useRef();
    const [urls, setUrl] = useState([]);
    const [loading, setLoading] = useState(false);
    const load_img = (e) => {
        const file = e.target.files[0] || undefined;
        var _URL = window.URL || window.webkitURL;
        var converted = [];
        if (file) {
            var img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = (e) => {
                const width = e.target.width;
                const height = e.target.height;
                const target_widths = [300, 600, 1000];
                const target_heights = target_widths.map(p => Math.round(p * height / width));

                for (var i = 0; i < target_widths.length; i++) {
                    const target_width = target_widths[i];
                    const target_height = target_heights[i];
                    try {
                        Resizer.imageFileResizer(
                            file,
                            target_width,
                            target_height,
                            "JPG",
                            100,
                            0,
                            async (uri) => {
                                const font = Math.round(target_width / 12).toString() + "px Cormorant Garamond"
                                watermark([uri])
                                    .dataUrl(watermark.text.center('HOMEKONNECT.COM', font, '#fff', 0.6))
                                    .then(async (img) => {
                                        var temp = await urltoBlob(img)
                                        const res = await compress(temp, 0.70)
                                        converted.push({
                                            size: target_width,
                                            uri: await filetoDataURL(res)
                                        });
                                        setList(converted);
                                    });
                            },
                            "base64",
                            200,
                            200
                        );
                    } catch (err) {
                    }
                }
            };
            img.src = objectUrl;
        }
    }

    async function begin_upload() {
        if (loading) return;
        setLoading(true)
        var token = token_ref.current.value;
        if (!token) return null;
        const api = "https://api.imgbb.com/1/upload?key=" + token;
        setUrl([]);
        var ours = [];
        for (var i = 0; i < ourlist.length; i++) {
            const p = ourlist[i];
            const formData = new FormData();
            formData.append("image", p.uri.replace(/^data:image\/(png|jpg|webp);base64,/, ""));
            const response = await fetch(api, {
                method: "POST",
                body: formData
            });
            if (response.ok) {
                const res = await response.json();
                ours.push({
                    size: p.size,
                    url: res.data.url
                })
            }
            else {
                ours.push({
                    size: p.size,
                    url: "Error uploading"
                });
            }
        }
        setUrl(ours);
        setLoading(false)

    }

    const opacity = (loading) ? "0.5" : "1";
    return (
        <div>
            Add watermark to images, upload and get public link to images.<br></br>
            <input accept="image/*" onChange={load_img} type="file" />
            <div>

                {ourlist.length && (<>
                    <p style={{ marginTop: '1em' }} className="capital-text">Preview</p>

                    <img style={{ width: '300px', marginBottom: '1em' }} src={ourlist[0].uri || ""} />

                    <p className="capital-text">Upload key</p>
                    <input id="key" autoComplete="on" ref={token_ref} type="text">

                    </input>

                    <div onClick={begin_upload} style={{ marginBottom: "5em", marginTop: '1em', opacity: opacity }} className="trans-btn-accent">
                        <TransparentButton value="Upload" style={{ borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
                    </div>

                    {urls.map(p => {
                        return (<>Size: {p.size}px <a href={p.url} target="_blank">{p.url}</a><br></br></>)
                    })}
                </>)}
            </div>
        </div>
    )
}

export default function Admin() {
    const key_ref = useRef();
    const [key, setKey] = useState()
    return (
        <div style={{ padding: '1em' }}>
            <h1>Home Konnect Admin</h1>
            {/* <hr></hr> */}
            <div style={{position: "relative", maxHeight: "200px"}}>
                <Pyro />
            </div>
            <div className="gen-text">
                <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Home">
                        <Home />
                    </Tab>
                    <Tab eventKey="cms" title="Content Management">
                        <CMS />
                    </Tab>
                    <Tab eventKey="imglink" title="Image Link Generator">
                        <ImageLink />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

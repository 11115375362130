import React, { useState, useEffect } from 'react'

import "../css/pillbox.css"

export default function PillBox(props) {
    const [state, setState] = useState(false)
    useEffect(() => {
        if(props.value != undefined) setState(props.value)
    }, [props.value])
    return (
        <span className="capital-text" style={{fontSize: '11px', letterSpacing: '0.1em'}} >
            <div onClick={() => { try{props.onChange(!state); if(!props.value) setState(!state);}catch(err){}  }} className={state ? "pillbox pillbox-selected" : "pillbox pillbox-unselected"} {...props}>
                {props.children}
            </div>
        </span>
    )
}

import React from 'react';
import "../css/pyro.scss"

export default function Pyro() {
    return (
        <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
        </div>
    );
}

import React from 'react'

export function TransparentButton(style) {

    return (
        <div style={{  padding: "4px", border: style.style.borderStyle }}>
            <div className="trans-btn capital-text" {...style}>
                {style.value}
            </div>
        </div>
    );
}


export function Button(style) {
    var button_type = "btn capital-text " + style.style.buttonColor;
    return (
        <div style={{  padding: "4px", background: style.style.backgroundColor, border: style.style.border }}>
            <div className={button_type} {...style}>
                {style.value}
            </div>
        </div>
    )
}

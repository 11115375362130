import React, { useEffect } from 'react'
import { Fade } from 'react-bootstrap'
import config from 'react-reveal/globals';

import "../css/popup.css"
config({ ssrFadeout: true });

export default function PopUp(props) {
    // useEffect(() => {
    //     if (props.active) {
    //         document.body.classList.add('noscroll');
    //     }
    //     else {
    //         document.body.classList.remove('noscroll')
    //     }
    // }, [props.active])

    function close_popup(e) {
        if (e.target.id == "closer" && props.close)
            props.close();
    }
    if (props.active)
        return (
            <div id="closer" onClick={close_popup} className="popup-wrap">
                <div className="popup-content-neo" style={props.style}>

                    {props.title && (<h1 style={{ fontSize: '2em' }}>{props.title}</h1>)}
                    <div style={props.message_style} className="gen-text">
                        {props.message}
                    </div>
                </div>
            </div>
        )
    else return (<></>)
}

export default class StorageBucket {
    constructor(ttl) {
        this.ttl = ttl;
        new Promise((resolve, reject) => {
            this.remove_expired();
            this.remove_lru(100);
            resolve(true);
        })
        
    }

    remove_expired = () => {
        var full = this.get_list();
        const now = new Date();
        for (var i = 0; i < full.length; i++) {

            if (full[i].data.expiry < now.getTime()) {
                localStorage.removeItem(full[i].key);
            }
        }
    }

    remove_lru = (len) => {
        var full = this.get_list();
        full = full.sort((f, s) => f.data.expiry < s.data.expiry);

        var to_remove = full.splice(len, full.length);
        for (var i = 0; i < to_remove.length; i++) {
            localStorage.removeItem(to_remove[i].key);
        }
    }

    get_list() {
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while (i--) {
            try {
                values.push({ data: JSON.parse(localStorage.getItem(keys[i])), key: keys[i] });
            }
            catch(err){
                
            }
        }
        return values
    }
    store(key, value) {
        const now = new Date();
        const data = {
            value: value,
            expiry: now.getTime() + this.ttl
        }
        this.remove_lru(100);
        try {
            localStorage.setItem(key, JSON.stringify(data));
        }
        catch (err) {
        }

    }

    load(key) {
        const now = new Date();
        var data = localStorage.getItem(key);
        if (!data) return null;
        data = JSON.parse(data);
        if (data.expiry < now.getTime()) {
            localStorage.removeItem(key)
            return null;
        }
        return data.value;
    }
}
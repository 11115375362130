import {tamil} from "./Profanity"

function dynamic_profanity(){
    const a = window.profanity;
    if (!a) return tamil;
    const words = a.split("\n");
    return words
}

export default function Validate(field, min, max, regex, field_name, include, message) {
    const p_list = dynamic_profanity();
    if (!field) return [false, message? message : ("Please enter your " + field_name)];
    else if (field.length < min || field.length > max) {
        return [false, message? message :(field_name + " should be " + min.toString() + " to " + max.toString() + " characters in length")];
    }
    else if (include? regex.test(field) : !regex.test(field)) {
        return [false, message? message :(field_name + " is invalid or contains prohibited characters")];
    }
    else if(p_list.some(p=> field.toLowerCase().includes(p.toLowerCase()))){
        return [false, "Please recheck the contents of the field"];
    }
    return [true, ""];
}